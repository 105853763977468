<template>
  <div id="app">
    <app-splash-screen v-show="showSplashScreen" />
    <router-view v-show="!showSplashScreen" />
  </div>
</template>

<script>
import AppSplashScreen from '@/modules/core/components/AppSplashScreen'
import { collectClickAnalytics } from '@/plugins/analytics'

export default {
  name: 'App',
  components: {
    AppSplashScreen
  },
  computed: {
    showSplashScreen () {
      return (
        this.$store.state.core.app.isUpdating ||
        this.$store.state.core.app.loadings.length > 0
      )
    }
  },
  created () {
    document.querySelector(':root').style.setProperty(
      '--scrollbar-width',
      this.getScrollBarWidth() + 'px'
    )
    document.addEventListener('mousedown', collectClickAnalytics)
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', collectClickAnalytics)
  },
  methods: {
    getScrollBarWidth () {
      const outer = document.createElement('div')
      outer.className = 'el-scrollbar__wrap'
      outer.style.visibility = 'hidden'
      outer.style.width = '100px'
      outer.style.position = 'absolute'
      outer.style.top = '-9999px'
      document.body.appendChild(outer)

      const widthNoScroll = outer.offsetWidth
      outer.style.overflow = 'scroll'

      const inner = document.createElement('div')
      inner.style.width = '100%'
      outer.appendChild(inner)

      const widthWithScroll = inner.offsetWidth
      outer.parentNode.removeChild(outer)
      return widthNoScroll - widthWithScroll
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
}
.el-notification.app-notification {
  border-left: 5px solid var(--color-warning-500);
  --width: 600px;
  min-width: 300px;
  max-width: var(--width);
  width: auto;
  right: 0;
  left: 50%;
  transform: translate(-50%, 0);
  top: 4px !important;
  &.app-notification--pulse {
    animation: warning-pulse 2s infinite;
  }
}

@keyframes warning-pulse {
	0% {
		box-shadow: 0 0 0 0 var(--color-warning-500);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
</style>

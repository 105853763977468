import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'

function makeTankForm (data) {
  const form = {
    id: null,
    __v: undefined,
    shape: 'rectangle',
    size: {
      length: undefined,
      width: undefined,
      radius: undefined,
      height: undefined
    },
    siteId: null,
    virtualSiteIds: [],
    color: '',
    name: ''
  }
  if (data) {
    form.id = data.id
    form.__v = data.__v
    form.shape = data.shape
    form.siteId = data.siteId
    form.virtualSiteIds = data.virtualSiteIds.slice()
    form.color = data.color
    form.name = data.name
    form.size.length = data.size?.length
    form.size.width = data.size?.width
    form.size.radius = data.size?.radius
    form.size.height = data.size?.height
  }
  return form
}

function makeSiteForm (data) {
  const form = {
    id: null,
    __v: undefined,
    name: '',
    color: '#616161',
    tankIds: []
  }
  if (data) {
    form.id = data.id
    form.__v = data.__v
    form.name = data.name
    form.color = data.color
    form.tankIds = data.tankIds.slice()
  }
  return form
}

function makeVirtualSiteForm (data) {
  const form = {
    id: null,
    __v: undefined,
    name: '',
    color: '#616161',
    tankIds: [],
    tanksTimeLimits: []
  }
  if (data) {
    form.id = data.id
    form.__v = data.__v
    form.name = data.name
    form.color = data.color
    form.tankIds = data.tankIds.slice()
    form.tanksTimeLimits = cloneDeep(data.tanksTimeLimits)
  }
  return form
}

export default {
  namespaced: true,

  state: {
    tankForm: makeTankForm(),
    siteForm: makeSiteForm(),
    virtualSiteForm: makeVirtualSiteForm()
  },

  getters: {
    getField
  },

  mutations: {
    updateField,

    RESET_TANK_FORM: state => {
      state.tankForm = makeTankForm()
    },
    SET_TANK_FORM: (state, data) => {
      state.tankForm = makeTankForm(data)
    },

    RESET_SITE_FORM: state => {
      state.siteForm = makeSiteForm()
    },
    SET_SITE_FORM: (state, data) => {
      state.siteForm = makeSiteForm(data)
    },

    RESET_VIRTUAL_SITE_FORM: state => {
      state.virtualSiteForm = makeVirtualSiteForm()
    },
    SET_VIRTUAL_SITE_FORM: (state, data) => {
      state.virtualSiteForm = makeVirtualSiteForm(data)
    }
  }
}

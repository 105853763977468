import { fetchPermissionsByUser } from '@/modules/core/api/acl'

class ACL {
  P = {
    NO: 0,
    R: 1,
    W: 2,
    X: 3
  }
  R = {
    c: (...args) => Array.prototype.join.call(args, '::'),
    Factory: 'factory',
    Archive: 'archive',
    Site: id => `site-${id}`,
    Tank: id => `tank-${id}`,
    Reports: 'reports',
    FactoryReport: 'factoryReport',
    FactoryBriefReport: 'factoryBriefReport',
    FishSaleReport: 'fishSaleReport',
    FishMovingReport: 'fishMovingReport',
    FeedingReport: 'feedingReport',
    StocksStateReport: 'stocksStateReport',
    StockFeedsReport: 'stockFeedsReport',
    StockMedicinesReport: 'stockMedicinesReport',
    Multifactories: 'multifactories',
    Supervisor: 'supervisor',
    Stocks: 'stocks',
    Stock: id => `stock-${id}`,
    Registries: 'registries',
    FeedProducers: 'feedProducers',
    FeedingPlans: 'feedingPlans',
    Medicines: 'medicines',
    CommonReasons: 'commonReasons',
    FishKind: 'fishKind',
    SystemFeedProducers: 'systemFeedProducers',
    SystemFeedingPlans: 'systemFeedingPlans',
    Iot: 'iot',
    Thermooximeters: 'thermooximeters',
    Thermooximeter: id => `thermooximeter-${id}`,
    Events: 'events',
    Summary: 'summary',
    Control: 'control',
    Administration: 'administration',
    Users: 'users',
    Roles: 'roles',
    AuthLog: 'authLog',
    PreferencesFactory: 'preferencesFactory',
    Acl: 'acl'
  }

  constructor (permissionManager) {
    this.pm = permissionManager
    this.user = null
  }

  isAllowed (permission, resource) {
    return this.pm.isAllowed(permission, resource)
  }

  isAllowedPattern (permission, pattern) {
    return this.pm.isAllowedPattern(permission, pattern)
  }

  isSuperuser () {
    return !!this.user?.isSuperuser
  }

  async initByUser (user) {
    this.user = user
    await this.fetchPermissions()
  }

  async fetchPermissions () {
    const { data: permissions } = await fetchPermissionsByUser(this.user.id)
    this.pm.setPermissions(permissions)
  }

  reset () {
    this.user = null
    this.pm.reset()
  }
}

export default ACL

import Vue from 'vue'
import { fetchMomentMetricsByTank } from '@/modules/core/api/indicators'
import { makeTankMetrics } from '@/modules/core/use/indicators'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    byTanks: {}
  },

  getters: {
    byTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['archive/tanks'].reduce((acc, tank) => {
        acc[tank.id] = makeTankMetrics(state.byTanks[tank.id])
        return acc
      }, {})
    }
  },

  mutations: {
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_BY_TANK: (state, { tankId, data }) => {
      Vue.set(state.byTanks, tankId, Object.freeze(data))
    }
  },

  actions: {
    async fetchByTank ({ commit, rootGetters }, tankId) {
      commit('SET_LOADING', true)
      const timestamp = new Date().toISOString()
      try {
        const response = await fetchMomentMetricsByTank(
          tankId,
          timestamp,
          rootGetters['factory/metrics/tankMetricNames']
        )
        commit('SET_BY_TANK', {
          tankId,
          data: response.data
        })
        return response.data
      } catch (e) {
        Promise.reject(e)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  }
}

import ioClient from 'socket.io-client'

const isDev = process.env.NODE_ENV !== 'production'
export const io = ioClient(isDev ? 'http://localhost:3000' : '/')

export const N = {
  APP_IS_READY: 'appIsReady',
  APP_IS_UPDATING: 'appIsUpdating',
  START_APP_UPDATE_AT: 'startAppUpdateAt',
  TOUCH_TANKS_METRICS: 'touchTanksMetrics',
  TOUCH_SUBJECT_ACL: 'touchSubjectAcl',
  TOUCH_ACL_RESOURCES: 'touchAclResources',
  NEW_EVENT: 'newEvent',
  INVALIDATE_CONTROL_RECORDS: 'invalidateControlRecords'
}

export const EVENT_NAMES = {
  INSERT_FISH_KIND: 'fishKind.insert',
  UPDATE_FISH_KIND: 'fishKind.update',
  DELETE_FISH_KIND: 'fishKind.delete',
  INSERT_FISH_GENDER: 'fishGender.insert',
  UPDATE_FISH_GENDER: 'fishGender.update',
  DELETE_FISH_GENDER: 'fishGender.delete',
  INSERT_FISH_GENERATION_DATE: 'fishGenerationDate.insert',
  UPDATE_FISH_GENERATION_DATE: 'fishGenerationDate.update',
  DELETE_FISH_GENERATION_DATE: 'fishGenerationDate.delete',
  INSERT_FISH_WEIGHT: 'fishWeight.insert',
  UPDATE_FISH_WEIGHT: 'fishWeight.update',
  DELETE_FISH_WEIGHT: 'fishWeight.delete',
  INSERT_FISH_MOVING: 'fishMoving.insert',
  UPDATE_FISH_MOVING: 'fishMoving.update',
  DELETE_FISH_MOVING: 'fishMoving.delete',
  INSERT_FISH_CATCH: 'fishCatch.insert',
  UPDATE_FISH_CATCH: 'fishCatch.update',
  DELETE_FISH_CATCH: 'fishCatch.delete',
  INSERT_FISH_SEEDING: 'fishSeeding.insert',
  UPDATE_FISH_SEEDING: 'fishSeeding.update',
  DELETE_FISH_SEEDING: 'fishSeeding.delete',
  INSERT_FISH_MORTALITY: 'fishMortality.insert',
  UPDATE_FISH_MORTALITY: 'fishMortality.update',
  DELETE_FISH_MORTALITY: 'fishMortality.delete',
  UPDATE_FEEDING: 'feeding.update',
  INSERT_FEEDING: 'feeding.insert',
  DELETE_FEEDING: 'feeding.delete',
  INSERT_WATER_OXYGEN: 'waterOxygen.insert',
  UPDATE_WATER_OXYGEN: 'waterOxygen.update',
  DELETE_WATER_OXYGEN: 'waterOxygen.delete',
  INSERT_WATER_TEMPERATURE: 'waterTemperature.insert',
  UPDATE_WATER_TEMPERATURE: 'waterTemperature.update',
  DELETE_WATER_TEMPERATURE: 'waterTemperature.delete',
  INSERT_WATER_NITROGEN: 'waterNitrogen.insert',
  UPDATE_WATER_NITROGEN: 'waterNitrogen.update',
  DELETE_WATER_NITROGEN: 'waterNitrogen.delete',
  INSERT_WATER_NO2: 'waterNo2.insert',
  UPDATE_WATER_NO2: 'waterNo2.update',
  DELETE_WATER_NO2: 'waterNo2.delete',
  INSERT_WATER_NO3: 'waterNo3.insert',
  UPDATE_WATER_NO3: 'waterNo3.update',
  DELETE_WATER_NO3: 'waterNo3.delete',
  INSERT_WATER_NH4: 'waterNh4.insert',
  UPDATE_WATER_NH4: 'waterNh4.update',
  DELETE_WATER_NH4: 'waterNh4.delete',
  INSERT_WATER_PH: 'waterPh.insert',
  UPDATE_WATER_PH: 'waterPh.update',
  DELETE_WATER_PH: 'waterPh.delete',
  INSERT_DEGREEDAY: 'degreeday.insert',
  UPDATE_DEGREEDAY: 'degreeday.update',
  DELETE_DEGREEDAY: 'degreeday.delete',
  ADD_SITE: 'addSite',
  REMOVE_SITE: 'removeSite',
  ADD_VIRTUAL_SITE: 'addVirtualSite',
  REMOVE_VIRTUAL_SITE: 'removeVirtualSite',
  ADD_TANK: 'addTank',
  REMOVE_TANK: 'removeTank',
  RELOCATE_TANK: 'relocateTank',
  ADD_TANK_TO_VIRTUAL_SITES: 'addTankToVirtualSites',
  REMOVE_TANK_FROM_VIRTUAL_SITES: 'removeTankFromVirtualSites',
  RESET_TANK: 'resetTank',
  REVERT_RESET_TANK: 'revertResetTank',
  ARCHIVE_TANK: 'archiveTank',
  RESTORE_ARCHIVED_TANK: 'restoreArchivedTank',
  ADD_PRODUCTION_PLAN: 'addProductionPlan',
  UPDATE_PRODUCTION_PLAN: 'updateProductionPlan',
  REMOVE_PRODUCTION_PLAN: 'removeProductionPlan',
  ADD_PRODUCTION_PLAN_CORRECTION: 'addProductionPlanCorrection',
  REMOVE_PRODUCTION_PLAN_CORRECTION: 'removeProductionPlanCorrection',
  SET_SITE_DENSITY: 'setSiteDensity',
  SET_SITE_DEGREEDAY: 'setSiteDegreeday',
  SET_SITE_TEMPERATURE_MAP: 'setSiteTemperatureMap',
  ENABLE_THERMOOXIMETER: 'enableThermooximeter',
  DISABLE_THERMOOXIMETER: 'disableThermooximeter',
  SET_THERMOOXIMETER_LOCATION: 'setThermooximeterLocation',
  SET_THERMOOXIMETER_TANKS: 'setThermooximeterTanks',
  SET_THERMOOXIMETER_DEPTH: 'setThermooximeterDepth',
  INSERT_THERMOOXIMETER_WORK_RULE: 'insertThermooximeterWorkRule',
  UPDATE_THERMOOXIMETER_WORK_RULE: 'updateThermooximeterWorkRule',
  DELETE_THERMOOXIMETER_WORK_RULE: 'deleteThermooximeterWorkRule',
  CREATE_STOCK: 'createStock',
  UPDATE_STOCK: 'updateStock',
  DELETE_STOCK: 'deleteStock',
  ADD_STOCK_INCOME: 'addStockIncome',
  ADD_STOCK_OUTCOME: 'addStockOutcome',
  ADD_STOCK_TRANSFER: 'addStockTransfer',
  UPDATE_STOCK_TRANSFER: 'updateStockTransfer',
  DELETE_STOCK_TRANSFER: 'deleteStockTransfer'
}

export const SCOPES_BY_CORE_MODULE = [
  'water',
  'fish',
  'mortality',
  'feeding',
  'factorymap'
]

export const SCOPES_BY_SUPERVISOR_MODULE = [
  'supervisor'
]

export const SCOPES_BY_STOCKS_MODULE = [
  'stocks'
]

export const SCOPES_BY_IOT_MODULE = [
  'iot'
]

export const SCOPES_BY_MODULES = {
  core: SCOPES_BY_CORE_MODULE,
  supervisor: SCOPES_BY_SUPERVISOR_MODULE,
  stocks: SCOPES_BY_STOCKS_MODULE,
  iot: SCOPES_BY_IOT_MODULE
}

export const SCOPES = [].concat(
  SCOPES_BY_CORE_MODULE,
  SCOPES_BY_SUPERVISOR_MODULE,
  SCOPES_BY_STOCKS_MODULE,
  SCOPES_BY_IOT_MODULE
)

export const subscribe = function (eventName, fn) {
  io.on(eventName, fn)
  return () => io.off(eventName, fn)
}

export const getEventTankId = function (event) {
  const tank = event.subjects.find(s => s.type === 'tank')
  return tank?.id
}

export const getEventSiteId = function (event) {
  const site = event.subjects.find(s => s.type === 'site')
  return site && site.id
}

export const getEventVirtualSiteId = function (event) {
  const site = event.subjects.find(s => s.type === 'virtualSite')
  return site && site.id
}

export const getEventProductionPlanId = function (event) {
  const plan = event.subjects.find(s => s.type === 'plan')
  return plan && plan.id
}

export const getEventThermooximeterId = function (event) {
  const thermooximeter = event.subjects.find(s => s.type === 'thermooximeter')
  return thermooximeter && thermooximeter.id
}

export const getEventStockId = function (event) {
  const stock = event.subjects.find(s => s.type === 'stock')
  return stock && stock.id
}

export const getScopesByModule = function (module) {
  return SCOPES_BY_MODULES[module] || []
}

export default io
